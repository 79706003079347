


















































































































.liquidPartner {
  width: 100%;
  max-width: 1368px;
  position: relative;
  padding: 1.25rem;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    margin: 0px auto;
    padding: 0px 1.25rem 1.25rem;
  }
  .btn-contact {
    text-align: right;
    margin-bottom: 15px;
    button {
      padding: 5px 15px;
      color: #f5f6f7;
      box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
      background-color: #5da000;
      background-image: conic-gradient(from 1turn, #885702, #c57b14);
      border-radius: 6.25rem;
      font-weight: bold;
      border: none;
    }
  }
  h3 {
    text-align: center;
    color: #ffa300;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .description {
    h5 {
      text-align: center;
      color: #ffa300;
      font-weight: bold;
      margin-bottom: 20px;
    }
    p {
      text-align: center;
      color: #ffffff;
      margin-bottom: 20px;
    }
  }
  .group-partner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .partner-item {
      width: 24%;
      background: #1e2024;
      border: 1px solid #5da000;
      border-radius: 10px;
      padding: 10px 5px;
      margin: 0px 0.5% 20px;
      @media (max-width: 1200px) {
        width: 48%;
        margin-bottom: 20px;
      }
      @media (max-width: 575px) {
        width: 100%;
      }
      .item-image {
        width: 150px;
        height: 150px;
        margin: 0 auto;
        position: relative;
        display: flex;
        align-items: center;
        img {
          // position: absolute;
          // top: 0;
          width: 100%;
          height: auto;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }
      }
      .item-content {
        margin: 20px auto 0px;
        width: 100%;
        max-width: 250px;
        p {
          font-size: 18px;
          color: #ffffff;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        }
      }
    }
  }
  .btn-load {
    background-color: #5da000;
    background-image: conic-gradient(from 1turn, #458802, #7bc514);
    border-radius: 20px;
    border: none;
    margin: 20px auto 0px;
  }
  .content-box {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    .content-item {
      margin-top: 50px;
      display: flex;
      &:nth-child(odd) {
        flex-direction: row-reverse;
        @media (max-width: 767px) {
          flex-direction: column-reverse;
        }
        .content-item-image {
          @media (max-width: 767px) {
            text-align: center;
            margin-bottom: 20px;
          }
          img {
            width: 300px;
            height: 100%;
            right: 0px;
            @media (max-width: 767px) {
              position: inherit;
            }
          }
        }
      }
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (max-width: 767px) {
        flex-direction: column-reverse;
      }
      .content-item-image {
        width: 48%;
        height: 300px;
        position: relative;
        @media (max-width: 767px) {
          width: 100%;
          text-align: center;
        }
        img {
          position: absolute;
          top: 0;
          width: 300px;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 10px;
          @media (max-width: 767px) {
            position: inherit;
          }
        }
      }
      .content-item-text {
        width: 48%;
        @media (max-width: 767px) {
          width: 100%;
          text-align: center;
        }
        h5 {
          font-weight: bold;
          color: #ffa300;
        }
        p {
          color: #ffffff;
        }
      }
    }
  }
}
